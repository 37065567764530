import * as Yup from 'yup';
import { valueToCommaSeparatedString } from '@/src/util/number';

export const calculatorSchema = () => {
  return Yup.object({
    residencyStatus: Yup.string().required('Residency status is required.'),
    propertyValue: Yup.number()
      .required('Property value is required.')
      .positive()
      .min(312500, 'Property value must be 312,500 or more.')
      .max(2_000_000_000, 'Property value must not exceed 2,000,000,000 AED.')
      .typeError('Property value is  a number.')
      .transform((_o, v) => parseFloat(v?.replace(/,/g, ''))),
    downPayment: Yup.number()
      .required('Down payment is required.')
      .positive()
      .max(
        Yup.ref('propertyValue'),
        'Down payment must not exceed property value.',
      )
      .test({
        name: 'min',
        message: (value) => {
          const { residencyStatus, propertyValue } = value;
          const minDownPayment = calcMinDownPayment(
            residencyStatus,
            propertyValue,
          );
          return `Minimum down payment is ${valueToCommaSeparatedString(
            Math.round(minDownPayment),
          )}`;
        },
        params: {
          propertyValue: Yup.ref('propertyValue'),
          residencyStatus: Yup.ref('residencyStatus'),
        },
        test: function (value) {
          const propertyValue =
            Number(this.resolve(Yup.ref('propertyValue'))) || 0;

          const minDownPayment = calcMinDownPayment(
            this.resolve(Yup.ref('residencyStatus')),
            propertyValue,
          );
          return value >= minDownPayment;
        },
      })
      .typeError('Down payment is a number.')
      .transform((_o, v) => parseFloat(v?.replace(/,/g, ''))),
    loanTerm: Yup.number()
      .required('Loan duration is required.')
      .positive()
      .min(1, 'Minimum loan duration is 1 year')
      .max(25, 'Maximum loan duration is 25 year')
      .typeError('Loan duration is a number.'),
  });
};

const calcMinDownPayment = (residencyStatus: string, propertyValue: number) => {
  const percentage = getDownPaymentPercentageByResidencyStatus(residencyStatus);
  const minDownPayment = propertyValue * percentage;

  return minDownPayment;
};

const getDownPaymentPercentageByResidencyStatus = (residencyStatus: string) => {
  if (residencyStatus === 'NON_RESIDENT' || residencyStatus === 'RESIDENT') {
    return 0.2;
  }
  return 0.15;
};
