import type { MouseEventHandler, ReactNode } from 'react';
import React from 'react';
import { cn } from '@/src/util/tailwind';

const sizeClasses = {
  sm: 'p-[10]',
  lg: 'p-4',
};

const themeClasses = {
  primary:
    'bg-black border rounded-[30px] disabled:hover:outline-none hover:outline hover:outline-accent',
  white:
    'bg-white text-black border rounded-[30px] disabled:hover:bg-white hover:bg-natural-gray-2',
};
type HoloIconButtonProps = {
  Icon: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  size: keyof typeof sizeClasses;
  theme: keyof typeof themeClasses;
  extraClasses?: string;
  disabled?: boolean;
  hidden?: boolean;
};

const HoloIconButton = (props: HoloIconButtonProps) => {
  const { onClick, size, theme, extraClasses, Icon, disabled, hidden } = props;

  return (
    <button
      disabled={disabled}
      hidden={hidden}
      onClick={onClick}
      className={cn(
        sizeClasses[size],
        themeClasses[theme],
        'border',
        disabled && 'opacity-40',
        extraClasses,
      )}
    >
      {Icon}
    </button>
  );
};

export default HoloIconButton;
