import '@fontsource/lexend';
import React, { useRef } from 'react';
import type { FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useQuery } from 'react-query';
import { calculatorSchema } from '@/src/components/validationSchemas/calculatorSchema';
import { DirUtils } from '@/src/util/dir';
import { isLoggedInAdmin } from '@/src/util/auth';

import AppSliderInput from '@/components/common/Input/AppSliderInput';
import HoloButton from '@/components/common/Button/HoloButton';
import CalculatorRadioField from '@/components/Calculator/CalculatorRadioField';
import CalculatorResultCell from '@/components/Calculator/CalculatorResultCell';

import { moneyCommaSeperated } from '@/src/util/string';
import LeadInputComma from '@/components/common/Input/LeadInputComma';
import {
  valueToCommaSeparatedString,
  commaSeparatedToNumber,
} from '@/src/util/number';
import RoutesEnum from '@/src/enums/routes.enum';
import { FixedRateTerm, MortgageType } from '@/src/enums/calculator.enum';
import { getLowestInterest } from '@/src/services/calculator/getLowestInterest';
import { ResidenceStatusEnum } from '@/src/enums/residence-status.enum';
import { numberWithCommas } from '@/src/util/calculation';
import { cn } from '@/src/util/tailwind';
import ClientOnlyComponent from '@/components/ClientOnlyComponent';

export const Calculator = () => {
  const isLoggedIn = isLoggedInAdmin();
  const { t, i18n } = useTranslation('calculator');

  const { t: tCommon } = useTranslation('app-common');

  const formikRef = useRef<FormikProps<typeof initialValues>>();

  const { data: interestResult, refetch } = useQuery({
    queryKey: ['lowestInterest'],
    queryFn: ({ signal }) => {
      const formValues = formikRef.current?.values;
      return getLowestInterest({
        signal,
        params: {
          type: formValues.mortgageProducts,
          residency:
            formValues.residencyStatus !== ResidenceStatusEnum.EMPTY
              ? formValues.residencyStatus
              : undefined,
          fixedRateTerm:
            formValues.mortgageProducts !== MortgageType.VARIABLE
              ? formValues.years
              : undefined,
        },
      });
    },
  });

  const isArabic = DirUtils.isRtl(i18n?.language);
  const initialValues = {
    loanDuration: 25,
    years: FixedRateTerm.ONE_TO_TWO,
    mortgageProducts: MortgageType.FIXED,
    downPayment: numberWithCommas(200_000),
    propertyValue: numberWithCommas(1_000_000),
    residencyStatus: ResidenceStatusEnum.EMPTY,
  };
  const radioFields = {
    residencyStatus: {
      name: 'residencyStatus',
      label: t('residencyStatus'),
      options: [
        {
          value: ResidenceStatusEnum.EMPTY,
          label: t('residencyStatuses.national'),
        },
        {
          value: ResidenceStatusEnum.RESIDENT,
          label: t('residencyStatuses.resident'),
        },
        {
          value: ResidenceStatusEnum.NON_RESIDENT,
          label: t('residencyStatuses.nonResident'),
        },
      ],
    },
    mortgageProducts: {
      name: 'mortgageProducts',
      label: t('popularMortgageProducts'),
      options: [
        { value: MortgageType.FIXED, label: t('Fixed') },
        { value: MortgageType.VARIABLE, label: t('Variable') },
      ],
    },
    years: {
      name: 'years',
      options: [],
      hasCheckIcon: true,
      containerClass: '!bg-white gap-x-2',
      tag: (value: string) => (
        <div className="flex flex-col items-center justify-center space-y-1 py-[22px] text-sm">
          <span className="font-semibold">{value}</span>
          <span className="text-natural-darkGray-2">{t('years')}</span>
        </div>
      ),
    },
  };

  radioFields.years.options = [
    {
      value: FixedRateTerm.ONE_TO_TWO,
      label: radioFields.years.tag('1-2'),
      className: 'border border-natural-gray-2 rounded-md',
    },
    {
      value: FixedRateTerm.THREE_TO_FOUR,
      label: radioFields.years.tag('3-4'),
      className: 'border border-natural-gray-2 rounded-md',
    },
    {
      value: FixedRateTerm.FIVE_OR_MORE,
      label: radioFields.years.tag('+5'),
      className: 'border border-natural-gray-2 rounded-md',
    },
  ];

  const monthlyCostCalculation = ({
    propertyValue,
    downPayment,
    loanDuration,
  }: {
    propertyValue: number;
    downPayment: number;
    loanDuration: number;
  }) => {
    if (!propertyValue || !downPayment || !loanDuration) {
      return 0;
    }

    const annualInterestRate = Number(interestResult?.interestRate);
    const downPaymentPercentage = Math.round(
      (Number(downPayment) / Number(propertyValue)) * 100,
    );
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    const months = Number(loanDuration) * 12;

    const denominator = 1 - Math.pow(1 + monthlyInterestRate, -months);
    const loanAmount =
      (Number(propertyValue) * (100 - downPaymentPercentage)) / 100;
    const monthlyEMI = loanAmount * (monthlyInterestRate / denominator);
    return moneyCommaSeperated(Math.round(monthlyEMI));
  };

  return (
    <div className="relative w-full">
      <div className="mx-auto flex max-w-6xl flex-col items-center justify-center rounded-b-3xl bg-black md:h-[720px] md:flex-row md:rounded-none md:px-6 2xl:px-0">
        <div className="md:w-1/2">
          <div className="w-full items-center space-y-6 p-8 py-12 text-center md:justify-center md:p-0 md:py-8 md:text-left md:rtl:text-right">
            <h1 className="pe-6 text-4xl font-bold text-white md:flex md:flex-col md:text-[56px] md:leading-[56px] md:rtl:leading-[60px]">
              {t('title')}
            </h1>
            <p
              className="text-base text-stone-300 md:text-xl"
              dangerouslySetInnerHTML={{
                __html: t('subtitle'),
              }}
            ></p>
          </div>
        </div>
        <div className="calculator-gradient-background h-6 w-[285px] rounded-tl-3xl rounded-tr-3xl xs:w-[342px] md:hidden"></div>
        <div className="relative z-50 flex items-center drop-shadow md:top-28 md:w-1/2">
          <Formik
            validationSchema={calculatorSchema}
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={() => {
              // do nothing
            }}
          >
            {({ values, setFieldValue, errors }) => {
              const loanDuration = +values.loanDuration;
              const isLoanDurationVisible =
                (loanDuration > 2 && isArabic) ||
                (loanDuration > 1 && !isArabic);

              return (
                <div className="relative w-full rounded-e-3xl">
                  <div className="calculator-gradient-background absolute left-[-24px] top-[48px] hidden h-[calc(100%-104px)] w-6 rounded-bl-3xl rounded-tl-3xl md:flex"></div>
                  <Form
                    onChange={(e) => {
                      if (
                        e.target instanceof HTMLInputElement &&
                        [
                          'residencyStatus',
                          'mortgageProducts',
                          'years',
                        ].includes(e.target.name)
                      ) {
                        setTimeout(() => {
                          refetch();
                        }, 300);
                      }
                    }}
                  >
                    <div className="flex flex-col space-y-6 rounded-t-3xl bg-white p-6">
                      <CalculatorRadioField
                        values={values}
                        radioField={radioFields.residencyStatus}
                        onChange={(e) =>
                          setFieldValue('residencyStatus', e.target.value)
                        }
                      />
                      <div className="flex  gap-x-6 ">
                        <div className="flex-1 space-y-[6px]">
                          <label className="text-sm font-medium">
                            {t('PropertyValue')}
                          </label>

                          <LeadInputComma
                            name="propertyValue"
                            type="text"
                            value={values.propertyValue}
                            errorText={errors?.propertyValue as string}
                            onChange={(e) => {
                              setFieldValue('propertyValue', e.target.value);
                            }}
                            EndSideComponent={
                              <div className="flex h-full w-full items-center justify-center border-s px-3 text-natural-darkGray-2">
                                AED
                              </div>
                            }
                          />
                        </div>
                        <div className="flex-1 space-y-[6px]">
                          <label className="text-sm font-medium">
                            {t('DownPayment')}
                          </label>

                          <LeadInputComma
                            name="downPayment"
                            type="text"
                            value={values.downPayment}
                            errorText={errors?.downPayment as string}
                            onChange={(e) => {
                              setFieldValue('downPayment', e.target.value);
                            }}
                            EndSideComponent={
                              <div className="flex h-full w-full items-center justify-center border-s px-3 text-natural-darkGray-2">
                                AED
                              </div>
                            }
                          />
                        </div>
                      </div>
                      <AppSliderInput
                        min={1}
                        max={25}
                        value={values.loanDuration}
                        type="range"
                        isRtl={isArabic}
                        id="loanDuration"
                        name="loanDuration"
                        label={
                          <div className="flex justify-between">
                            <p>{t('LoanDuration')}</p>
                            <p>
                              <span>
                                {isLoanDurationVisible && `${loanDuration} `}
                              </span>
                              {t('year', {
                                count: loanDuration,
                                ns: 'app-calculator',
                              })}
                            </p>
                          </div>
                        }
                        onChange={(e) =>
                          setFieldValue('loanDuration', e.target.value)
                        }
                      />

                      <CalculatorRadioField
                        radioField={radioFields.mortgageProducts}
                        values={values}
                        onChange={(e) =>
                          setFieldValue('mortgageProducts', e.target.value)
                        }
                      />

                      {values.mortgageProducts !== MortgageType.VARIABLE && (
                        <CalculatorRadioField
                          values={values}
                          radioField={radioFields.years}
                          onChange={(e) =>
                            setFieldValue('years', e.target.value)
                          }
                        />
                      )}
                    </div>
                  </Form>
                  <div className="bg-success-2 p-6">
                    <div className="space-y-2 pb-4">
                      <CalculatorResultCell
                        valueClass="!text-[18px] md:!text-4xl font-semibold"
                        label={t('MortgagePayment', { ns: 'app-calculator' })}
                        currencyClass="!text-[18px] font-semibold md:font-normal md:!text-base"
                        value={
                          errors.downPayment || errors.propertyValue
                            ? '--'
                            : valueToCommaSeparatedString(
                                (commaSeparatedToNumber(values.propertyValue) ||
                                  0) -
                                  (commaSeparatedToNumber(values.downPayment) ||
                                    0),
                              )
                        }
                      />
                      <CalculatorResultCell
                        label={t('MonthlyCost', { ns: 'app-calculator' })}
                        valueClass="!text-[18px] md:!text-4xl font-semibold"
                        currencyClass="!text-[18px] font-semibold md:font-normal md:!text-base"
                        value={
                          errors.downPayment ||
                          errors.propertyValue ||
                          !interestResult?.interestRate
                            ? '--'
                            : monthlyCostCalculation({
                                propertyValue:
                                  commaSeparatedToNumber(
                                    values.propertyValue,
                                  ) || 0,
                                downPayment:
                                  commaSeparatedToNumber(values.downPayment) ||
                                  0,
                                loanDuration:
                                  commaSeparatedToNumber(
                                    +values.loanDuration,
                                  ) || 0,
                              })
                        }
                      />
                    </div>
                    {/* <div className="flex flex-col space-y-2 border-t border-success-3 border-opacity-50 py-4">
                      <CalculatorResultCell
                        value="5,000"
                        valueClass="font-bold"
                        currencyClass="!font-bold"
                        label={t('totalUpfrontCost')}
                        labelClass="font-semibold text-natural-darkGray-2"
                      />
                      <CalculatorResultCell
                        value="225"
                        label={
                          <span className="flex items-center gap-x-1 text-natural-darkGray-2">
                            <span
                              style={{
                                transform: isArabic
                                  ? 'rotateY(180deg)'
                                  : 'none',
                              }}
                            >
                              <HoloIcon
                                id="sub_right"
                                className="h-[10px] w-3 fill-[#747B83]"
                              />
                            </span>
                            <span>{t('DownPayment')}</span>
                          </span>
                        }
                      />
                      <CalculatorResultCell
                        value="23"
                        label={
                          <span className="flex items-center gap-x-1 text-natural-darkGray-2">
                            <span
                              style={{
                                transform: isArabic
                                  ? 'rotateY(180deg)'
                                  : 'none',
                              }}
                            >
                              <HoloIcon
                                id="sub_right"
                                className="h-[10px] w-3 fill-[#747B83]"
                              />
                            </span>
                            <span className="me-1">{t('fees')}</span>
                          </span>
                        }
                      />
                    </div>
                    */}
                    <div className="text-center text-xs">
                      {t('calculationLiveNote')}
                    </div>
                  </div>
                  <ClientOnlyComponent>
                    <Link
                      href={
                        isLoggedIn
                          ? RoutesEnum.DASHBOARD
                          : RoutesEnum.MORTGAGE_PRODUCTS_SERVICES
                      }
                    >
                      <HoloButton
                        theme="primary"
                        size="lg"
                        extraClasses={cn(
                          'w-full rounded-none rounded-b-3xl',
                          DirUtils.getLang(i18n?.language) +
                            '-get-started-calculator',
                        )}
                        text={
                          isLoggedIn
                            ? tCommon('Dashboard')
                            : tCommon('GetStarted')
                        }
                      />
                    </Link>
                  </ClientOnlyComponent>
                </div>
              );
            }}
          </Formik>
        </div>
      </div>
      <div className="absolute bottom-0 z-10 mx-auto h-24 w-full bg-white pt-8 md:relative md:h-48"></div>
    </div>
  );
};
