import type { InputHTMLAttributes, ReactNode } from 'react';
import React from 'react';
import { cn } from '@/src/util/tailwind';

export type AppInputProps = InputHTMLAttributes<HTMLInputElement> & {
  EndSideComponent?: ReactNode;
  LeftSideComponent?: ReactNode;
  label?: string;
  inputClasses?: string;
  errorText?: string;
  labelClasses?: string;
};

const AppInput = (props: AppInputProps) => {
  const {
    LeftSideComponent,
    EndSideComponent,
    label,
    labelClasses,
    inputClasses,
    errorText,
    disabled,
    ...inputProps
  } = props;
  const htmlForm = inputProps.id ?? inputProps.name;

  const labelClassNames = cn(labelClasses, 'text-sm', {
    'text-natural-darkGray-2': disabled,
  });

  const inputClassNames = cn(
    'min-h-[48px] w-full min-w-full appearance-none rounded-md border border-natural-gray-2 bg-white px-2 py-2 indent-2 focus:outline-none hover:focus:outline-none',
    inputClasses,
    {
      'text-natural-darkGray-2': disabled,
    },
  );

  return (
    <div className="flex flex-col gap-[6px]">
      <label htmlFor={htmlForm} className={labelClassNames}>
        {label}
      </label>
      <div className="relative w-full">
        <div
          className="absolute top-1/2 h-full -translate-y-1/2"
          style={{
            insetInlineStart: '0',
          }}
        >
          {LeftSideComponent}
        </div>
        <input
          {...inputProps}
          disabled={disabled}
          className={inputClassNames}
        />
        <div
          className="absolute top-1/2 h-full -translate-y-1/2"
          style={{
            insetInlineEnd: '0',
          }}
        >
          {EndSideComponent}
        </div>
      </div>
      {errorText && <p className="text-xs text-red-500">{errorText}</p>}
    </div>
  );
};

export default AppInput;
