import React from 'react';
import Image from 'next/image';

import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import HoloButton from '@/components/common/Button/HoloButton';
import RoutesEnum from '@/src/enums/routes.enum';
import { isLoggedInAdmin } from '@/src/util/auth';
import { DirUtils } from '@/src/util/dir';
import ClientOnlyComponent from '@/components/ClientOnlyComponent';
import { cn } from '@/src/util/tailwind';
import FreeImage from '../../assets/free.png';
import HoloText from '../common/Typography/HoloText';

const AppFreeBanner = () => {
  const { t, i18n } = useTranslation('app-home');
  const isLoggedIn = isLoggedInAdmin();

  return (
    <section className="bg-white">
      <div className="m-auto min-h-[480px] max-w-6xl md:min-h-[625px]">
        <div className="flex w-full max-w-6xl flex-col items-center gap-12 px-6 py-6 pb-[68px] pt-12 md:gap-[92px] md:py-[96px] xl:px-[0px]">
          <div className=" flex flex-col items-center">
            <HoloText
              tag="h1"
              text={`${t('FreeBanner.title')}`}
              extraClasses="text-center !text-3xl md:!text-5xl"
            />
            <div className="py-6 md:py-8">
              <Image alt="free" src={FreeImage} />
            </div>
            <HoloText
              tag="p"
              flavour="medium"
              extraClasses="text-center text-natural-darkGray-2 max-w-[532px]"
              text={`${t('FreeBanner.description')}`}
            />
            <ClientOnlyComponent>
              <Link
                href={
                  isLoggedIn
                    ? RoutesEnum.DASHBOARD
                    : RoutesEnum.MORTGAGE_PRODUCTS_SERVICES
                }
              >
                <HoloButton
                  theme="primary"
                  size="lg"
                  extraClasses={cn(
                    'mt-12 md:mt-16',
                    !isLoggedIn
                      ? DirUtils.getLang(i18n?.language) + '-get-started-free'
                      : '',
                  )}
                  text={t(isLoggedIn ? 'Dashboard' : 'GetStarted', {
                    ns: 'app-common',
                  })}
                />
              </Link>
            </ClientOnlyComponent>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppFreeBanner;
