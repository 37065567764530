import React, { useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from 'next-i18next';
import HoloIconButton from '@/components/common/Button/HoloIconButton';
import HoloIcon from '@/components/common/Icon/HoloIcon';
import useWindowSize from '@/src/hooks/useWindowSize';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { DirUtils } from '@/src/util/dir';
import HoloText from '@/components/common/Typography/HoloText';
import { cn } from '@/src/util/tailwind';

const REVIEWS_COUNT = 3;

export class AppGoogleReviewsSliderProps {
  localePath: string;
}

const AppGoogleReviewsSlider = (props: AppGoogleReviewsSliderProps) => {
  const { localePath } = props;
  const { isSmall, isXSmall, isMedium, isLarge } = useWindowSize();
  const { t: tSamples, i18n } = useTranslation(localePath, {
    keyPrefix: 'Ratings.Samples',
  });
  const isRtl = DirUtils.isRtl(i18n?.language);

  let lengthTowShow = 1;
  if (isXSmall || isSmall || isMedium) {
    lengthTowShow = 1;
  } else if (isLarge) {
    lengthTowShow = 2;
  } else {
    lengthTowShow = 3;
  }
  const ref = useRef<Carousel>();
  const [selectedIdx, setSelectedIdx] = useState(0);

  return (
    <>
      <div
        style={{
          direction: 'initial',
        }}
      >
        <Carousel
          ref={(el) => (ref.current = el)}
          showThumbs={false}
          centerSlidePercentage={100 / lengthTowShow}
          centerMode
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          className={cn(
            'flex max-w-[300px] items-center justify-center sm:max-w-[380%] md:max-w-[100vw] md:px-6 lg:px-0 xl:max-w-[1128px]',
          )}
        >
          {Array.from({ length: REVIEWS_COUNT }).map((review, idx) => (
            <div
              key={idx}
              className="flex justify-center lg:ms-5"
              style={{
                direction: isRtl ? 'rtl' : 'ltr',
              }}
            >
              <div
                className={`flex h-[360px] w-full max-w-[360px] flex-col items-start justify-between rounded-3xl 
              border border-natural-gray-2 bg-natural-gray-3 p-3 text-start sm:p-4 md:h-[300px] md:w-[360px]
          `}
              >
                <HoloText
                  tag="p"
                  flavour="large_regular"
                  extraClasses="text-start text-[17px] line-clamp-[11] md:line-clamp-[8]"
                  text={tSamples(idx + '.Content')}
                />
                <div className="mt-4 w-full">
                  <div className="flex w-full  items-start justify-start">
                    {[...Array(5).keys()].map((_, index) => (
                      <HoloIcon
                        key={index}
                        id="star"
                        className="h-7 w-3 fill-[#F2994A]"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="flex gap-4 lg:hidden">
        <HoloIconButton
          extraClasses="rounded-full"
          size="lg"
          disabled={selectedIdx < 1}
          theme="white"
          Icon={
            <HoloIcon
              id={isRtl ? 'arrow-right' : 'arrow-left'}
              className="h-6 w-6"
            />
          }
          onClick={() => {
            ref.current.decrement(lengthTowShow);
            setSelectedIdx(selectedIdx - lengthTowShow);
          }}
        />
        <HoloIconButton
          disabled={selectedIdx + lengthTowShow >= REVIEWS_COUNT}
          size="lg"
          theme="primary"
          Icon={
            <HoloIcon
              id={isRtl ? 'arrow-left' : 'arrow-right'}
              className="h-6 w-6 fill-white"
            />
          }
          onClick={() => {
            ref.current.increment(lengthTowShow);
            setSelectedIdx(selectedIdx + lengthTowShow);
          }}
        />
      </div>
    </>
  );
};

export default AppGoogleReviewsSlider;
