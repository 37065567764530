import React from 'react';
import { useTranslation } from 'next-i18next';
import HoloText from '@/components/common/Typography/HoloText';
import HoloIcon from '@/components/common/Icon/HoloIcon';
import AppGoogleReviewsSlider from '@/components/App/GoogleReview/AppGoogleReviewsSlider';

type AppRatingsProps = {
  localePath: string;
};

const AppRatings = (props: AppRatingsProps) => {
  const { localePath } = props;
  const { t } = useTranslation(localePath, { keyPrefix: 'Ratings' });
  return (
    <section className="bg-white">
      <div className="m-auto min-h-[788px] max-w-6xl md:min-h-[625px] ">
        <div className="flex w-full flex-col  items-center gap-12 px-6 py-6 pb-[68px] pt-12 md:gap-[92px] md:py-[96px] xl:px-[0px]">
          <div className="flex  flex-col items-center gap-12">
            <div className="flex  flex-col items-center gap-3 text-center">
              <HoloText
                tag="h1"
                text={t('Title')}
                extraClasses="!text-4xl md:!text-5xl text-center"
              />
              <p className="max-w-[786px] text-center">
                <HoloText
                  tag="span"
                  flavour="medium"
                  extraClasses="text-natural-darkGray-2 "
                  text={t('SubTitleMsg')}
                />
              </p>
              <div className="flex items-center">
                <HoloText tag="p" text="4.9" />
                <div className="ml-4 flex">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <HoloIcon
                      key={index}
                      id="star"
                      className="h-4 w-5 scale-[1.7] fill-[#F2994A]"
                    />
                  ))}
                </div>
                <p>
                  <HoloText tag="span" text="powered by " />
                  <HoloText tag="span" flavour="p" text="Google" />
                </p>
              </div>
            </div>
            <AppGoogleReviewsSlider localePath={localePath} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppRatings;
