export const valueToCommaSeparatedString = (value: string | number) => {
  let oldValueToNumber = Number(value);
  if (typeof value === 'string') {
    const commaToNumber = commaSeparatedToNumber(value);
    if (commaToNumber === '') return '';
    oldValueToNumber = commaToNumber;
  }
  let tempValue = String(roundUp(oldValueToNumber));

  // remove any NON digit => D(non-digit)
  tempValue = tempValue.replace(/[^\d|\\.-]/g, '');

  //toFixed Number
  // /(\d)(?=(\d{3})+(?!\d))/g
  // 1st group it should start with a d(digit or [0-9])
  // 2nd group a positive lookahead ?= that will assure to match (3 digits or none)
  // which is not follow by a digit
  //finally replace every accure to the 1st group with an appended subfix of ,
  return tempValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const commaSeparatedToNumber = (value: string | number) => {
  return value === ''
    ? value
    : Number(String(value).replace(/[^\d|\\.-]/g, '')) ?? '';
};

export const roundUp = (amount: number) => {
  return Math.round((amount + Number.EPSILON) * 100) / 100;
};

export const toZeroIfNeeded = (value: number | string): number => {
  return Number(value) || 0;
};

export const toPercent = (value: number) => {
  return value / 100;
};

export const toRoundedCommaSeparatedNumber = (val: string | number) => {
  return valueToCommaSeparatedString(Math.round(Number(val)));
};
