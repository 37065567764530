import React from 'react';

// Define the props for the component
interface CalculatorResultCellProps {
  label: string | React.ReactNode;
  value: string | React.ReactNode;
  currency?: string | React.ReactNode;
  valueClass?: string;
  labelClass?: string;
  currencyClass?: string;
  containerClass?: string;
}

const CalculatorResultCell: React.FC<CalculatorResultCellProps> = ({
  label,
  value,
  currency = 'AED',
  valueClass,
  labelClass,
  currencyClass,
  containerClass,
}) => {
  return (
    <div className={`flex items-center justify-between ${containerClass}`}>
      <span className={`text-sm font-medium ${labelClass}`}>{label}</span>
      <span>
        <span className={`text-sm ${valueClass}`}>{value}</span>
        <span className={`ml-2 text-sm font-medium ${currencyClass}`}>
          {currency}
        </span>
      </span>
    </div>
  );
};

export default CalculatorResultCell;
