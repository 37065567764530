import type { ChangeEvent } from 'react';
import React from 'react';
import HoloIcon from '@/components/common/Icon/HoloIcon';
import { cn } from '@/src/util/tailwind';

type Option = {
  value: string;
  className?: string;
  selectedClass?: string;
  label: string | React.ReactNode;
};

type RadioField = {
  name: string;
  options: Option[];
  hasCheckIcon?: boolean;
  containerClass?: string;
  label?: string | React.ReactNode;
};

interface CalculatorRadioFieldProps {
  radioField: RadioField;
  values: { [key: string]: any };
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const CalculatorRadioField: React.FC<CalculatorRadioFieldProps> = ({
  radioField,
  values,
  onChange,
}) => (
  <div className="flex flex-col space-y-[6px]">
    <label className="text-sm font-medium leading-tight text-black">
      {radioField.label}
    </label>
    <div
      className={cn(
        'flex min-h-[44px] rounded-lg bg-neutral-100 p-[2px]',
        radioField.containerClass,
      )}
    >
      {radioField.options.map((option, index) => {
        const isSelected = values[radioField.name] === option.value;
        const baseClasses = 'relative flex-1 ';
        const customClass = isSelected ? option.selectedClass : '';
        const selectionClass = isSelected
          ? radioField.hasCheckIcon
            ? '!border-info-1'
            : 'rounded-md bg-white drop-shadow '
          : '';

        return (
          <div
            key={index}
            className={cn(
              baseClasses,
              option.className,
              selectionClass,
              customClass,
            )}
          >
            <label
              id={`radio-${radioField.name}-${index}`}
              className="flex h-full w-full cursor-pointer items-center justify-center"
            >
              <input
                type="radio"
                className="hidden"
                onChange={onChange}
                checked={isSelected}
                name={radioField.name}
                value={String(option.value)}
                id={`radio-${radioField.name}-${index}`}
              />
              <span className="whitespace-nowrap text-sm font-normal leading-tight text-black">
                {option.label}
              </span>
              {isSelected && radioField.hasCheckIcon && (
                <span className="absolute right-[10px] top-[10px]">
                  <HoloIcon
                    id="check_mark"
                    className="h-5 w-5 fill-[#1890FF]"
                  />
                </span>
              )}
            </label>
          </div>
        );
      })}
    </div>
  </div>
);

export default CalculatorRadioField;
