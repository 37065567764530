export enum MortgageType {
  FIXED = 'FIXED',
  VARIABLE = 'VARIABLE',
}

export enum FixedRateTerm {
  ONE_TO_TWO = 'ONE_TO_TWO',
  THREE_TO_FOUR = 'THREE_TO_FOUR',
  FIVE_OR_MORE = 'FIVE_OR_MORE',
}
