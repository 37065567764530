import type { InputHTMLAttributes, ReactNode } from 'react';
import React, { useEffect, useRef } from 'react';
import Style from './AppSliderInput.module.scss';
type AppSliderInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactNode;
  inputClasses?: string;
  isRtl?: boolean;
};

const AppSliderInput = (props: AppSliderInputProps) => {
  const { label, inputClasses, isRtl, ...inputProps } = props;
  const htmlForm = inputProps.id ?? inputProps.name;
  const ref = useRef<HTMLInputElement>();
  useEffect(() => {
    const slider = ref.current;

    const updateInputBg = (el: HTMLInputElement) => {
      if (!el) return;
      const min = +el.min;
      const max = +el.max;
      const value = +el.value;

      const percentage = ((value - min) / (max - min)) * 100;

      el.style.background = isRtl
        ? `linear-gradient(to right, #F5F6F6 ${100 - percentage}%, black ${
            100 - percentage
          }%, black 100%)`
        : `linear-gradient(to right, black 0%, black ${percentage}%, #F5F6F6 ${percentage}%, #F5F6F6 100%)`;
    };

    updateInputBg(slider);

    const updateInput = (event: Event) => {
      const el = event.target as HTMLInputElement;
      updateInputBg(el);
    };

    slider.addEventListener('input', updateInput);

    return () => {
      slider.removeEventListener('input', updateInput);
    };
  }, [isRtl]);

  return (
    <div className={`flex flex-col gap-[6px] ${Style['app-slider-input']}`}>
      <label htmlFor={htmlForm}>{label}</label>
      <input
        data-slider
        ref={ref}
        {...inputProps}
        className={`mt-4  w-full cursor-pointer rounded-md border-red-50
         bg-gray-200  accent-black  outline-red-500  ${inputClasses} `}
      />
    </div>
  );
};

export default AppSliderInput;
