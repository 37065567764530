import React, { useEffect, useState } from 'react';

import type { AppInputProps } from '@/components/common/Input/AppInput';
import AppInput from '@/components/common/Input/AppInput';
import { valueToCommaSeparatedString } from '@/src/util/number';
type LeadInputCommaProps = AppInputProps;

const LeadInputComma = (props: LeadInputCommaProps) => {
  const [commaedValue, setCommaedValue] = useState('');
  const { value } = props;
  useEffect(() => {
    const number = valueToCommaSeparatedString(value?.toString() ?? '');

    setCommaedValue(number);
  }, [value]);

  return (
    <AppInput inputMode="numeric" {...props} type="text" value={commaedValue} />
  );
};

export default LeadInputComma;
