import type { FixedRateTerm, MortgageType } from '@/src/enums/calculator.enum';
import type { ResidenceStatusEnum } from '@/src/enums/residence-status.enum';
import { axiosInstance } from '@/src/util/axios';

type Params = {
  type?: MortgageType;
  residency?: ResidenceStatusEnum;
  fixedRateTerm?: FixedRateTerm;
};

export const getLowestInterest = async ({
  params,
  signal,
}: {
  params: Params;
  signal: AbortSignal;
}) => {
  const { data } = await axiosInstance.get(`/products/lowest-interest`, {
    params,
    signal,
  });

  return data as unknown as {
    interestRate: number;
  };
};
