import Head from 'next/head';
import { MetaTagsEnum } from '@/src/enums/meta-tags.enum';

type customHeadProps = {
  pageTitle?: string;
  title: string;
  canonical?: string;
  description: string;
  image: string;
  imageAlt?: string;
  url: string;
  type?: string;
  hasFBDomainVerification?: boolean;
};

const CustomHead = ({
  pageTitle,
  title,
  canonical,
  description,
  image,
  url,
  type,
  hasFBDomainVerification = false,
}: customHeadProps) => {
  return (
    <Head>
      {/* Basic Meta Tags */}
      <title>{pageTitle ?? title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonical || url} />
      {hasFBDomainVerification && (
        <meta
          name="facebook-domain-verification"
          content={MetaTagsEnum.FB_DOMAIN_VERIFICATION_KEY}
        />
      )}
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type || 'website'} />
      <meta property="og:image" content={image} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      {/* Twitter Meta Tags  */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:domain" content={url} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={title} />
    </Head>
  );
};

export default CustomHead;
